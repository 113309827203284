import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { parseUrl } from 'query-string';
import Box from '../components/styles/Box';
import useGlobal from '../store';
import Page from '../components/Page';
import Container from '../components/styles/Container';
import Heading from '../components/styles/Heading';
import Input from '../components/styles/LargeInput';
import SubmitButton from '../components/styles/SubmitButton';
import FormBanner from '../components/styles/FormBanner';
import Subhead from '../components/styles/Subhead';

const ResetPage = ({ location }) => {
  const [, actions] = useGlobal();
  const { resetPasswordCommit } = actions;
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');

  const commit = async () => {
    if (await resetPasswordCommit({ token, email, password })) {
      await navigate('/login');
    }
  };

  useEffect(
    () => {
      if (location.search) {
        const qs = parseUrl(location.search).query;
        const { token: t, email: e } = qs;
        setToken(t);
        setEmail(e);
      }
    },
    [location, setToken, setEmail]
  );

  return (
    <Page>
      <FormBanner>
        <Subhead style={{ margin: '1em' }}>
          Your new password must be at least 8 characters long and contain at
          least 1 number or special character.
        </Subhead>
        <Container>
          <Heading>Reset Your Password</Heading>
          <Input
            id="password"
            onChange={e => setPassword(e.target.value)}
            placeholder="Enter password"
            value={password}
            type="password"
            color="black"
            width="250px"
          />
          <br />
          <Input
            id="passwordConfirm"
            onChange={e => setPasswordConfirm(e.target.value)}
            placeholder="Re enter your password"
            value={passwordConfirm}
            type="password"
            color="black"
            width="250px"
          />
          <Box my={6}>
            <SubmitButton
              disabled={!password || password !== passwordConfirm}
              onClick={commit}
            >
              Submit
            </SubmitButton>
          </Box>
        </Container>
      </FormBanner>
    </Page>
  );
};

ResetPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
};

export default ResetPage;
